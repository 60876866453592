
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

// Define the Auth context type
type AuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  loginWithRedirect: () => void;
  logout: () => void;
  user?: {
    name?: string;
    email?: string;
    picture?: string;
  };
};

// Create the Auth context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom provider component that combines Auth0Provider with our context
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [auth0Config, setAuth0Config] = useState({
    domain: '',
    clientId: ''
  });
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    // Fetch Auth0 configuration securely
    const loadAuth0Config = async () => {
      try {
        // Only include the minimal necessary Auth0 config for frontend use
        // We're using environment variables that are public-safe (VITE_ prefixed)
        const domain = import.meta.env.VITE_PUBLIC_AUTH0_DOMAIN || '';
        const clientId = import.meta.env.VITE_PUBLIC_AUTH0_CLIENT_ID || '';
        
        // Validate config
        if (!domain || !clientId) {
          console.error('Auth0 configuration not found. Make sure VITE_PUBLIC_AUTH0_DOMAIN and VITE_PUBLIC_AUTH0_CLIENT_ID environment variables are set in Netlify.');
        }
        
        setAuth0Config({ domain, clientId });
        setIsConfigLoaded(true);
      } catch (error) {
        console.error('Failed to load Auth0 configuration:', error);
        setIsConfigLoaded(true); // Set to true to avoid infinite loading state
      }
    };

    loadAuth0Config();
  }, []);

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || '/');
  };

  // Show loading state until config is loaded
  if (!isConfigLoaded) {
    return <div>Loading authentication configuration...</div>;
  }

  // We only need domain and clientId in the frontend
  // Other sensitive data like client_secret is handled by the Netlify function
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthContextConsumer>{children}</AuthContextConsumer>
    </Auth0Provider>
  );
};

// Consumer component that exposes Auth0 hooks via our context
const AuthContextConsumer = ({ children }: { children: ReactNode }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout: () => logout({ logoutParams: { returnTo: window.location.origin } }),
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to easily use the Auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
